.shortenText {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
}
.saving h1 {
  /* color: #6D87A4 !important; */
  color: #45505F !important;
  font-weight: bold;
  text-align: center;
}
.saving .heading {
  margin-bottom: 30px;
}
.saving .widget {
  border: 0.5px solid rgba(66, 98, 194, 0.1);
  height: 320px;
  border-radius: 40px;
}
.saving .partners {
  padding: 100px 40px 40px 40px;
}
.peersfer > h1 {
  /* color: #6D87A4 !important; */
  color: #45505F !important;
  font-weight: bold;
  text-align: center;
}
.divider {
  background: #3273DC;
  margin: auto;
  height: 2px;
  width: 130px;
  margin-bottom: 100px;
}
.line {
  background: #EEF3FC;
  height: 1px;
  width: 60%;
  margin: auto;
  margin-top:10%;
  margin-bottom: 8%;
}
.address p {
  display: block;
  margin-top: -15px;
}
.address {
  margin-top: 30px;
}
/* .crisp-client .crisp-1rjpbb7[data-full-view=true] .crisp-1rf4xdh .crisp-kquevr{
    bottom: 100px !important;
} */
/* .crisp-client .crisp-1rjpbb7[data-full-view=true] .crisp-1rf4xdh a.crisp-kquevr {
  bottom: 100px !important;
} */

.crisp-client .cc-kv6t[data-full-view=true] .cc-1xry .cc-unoo{
  bottom: 120px !important;
}

.crisp-client{
  bottom: 100px !important;
}

.clients img {
  margin-bottom: 10px;
  filter: grayscale(30%);
  /* mix-blend-mode: luminosity; */
  opacity: 0.9;
}
.clients h3 {
  margin-bottom: 8%;
  font-weight: normal;
}
.clients #byimin {
  width: 50%;
}
.clients #galaxy {
  width: 30%;
}
.clients #huiqiao {
  width: 40%;
}
.clients #shanghang {
  width: 70%;
}
.clients #mthui {
  width: 45%;
}
.clients #anteng {
  margin-top: 17px;
  width: 70%;
}
.clients #ukec {
  width: 70%;
}
.clients #estia {
  margin-top: 10px;
  width: 30%;
}
.clients #zhongjiang {
  margin-top: 15px;
  width: 70%;
}

.gold .widget {
  border: 0.5px solid rgba(66, 98, 194, 0.1);
  border-radius: 20px;
}

.gold h1 {
  color: #45505F !important;
  font-weight: bold;
}

.gold button{
  border: none;
  background-color: #F7B83B;
  transition-duration: 0.3s !important;
  color: white;
  font-weight: bold;
  font-size: medium;
  box-shadow: 0px 8px 20px rgba(230, 225, 190, 0.7);
  padding: 15px 25px 13px 25px;
  border-radius: 30px;
  
}

button:focus,
.gold button:active{
  outline:none !important;
}

.gold button:hover{
  box-shadow: 0px 10px 30px rgb(236, 226, 147);
  transform: translateY(-1px);
}

.gold .redemption img{
  width: 100%;

}

.gold .redemption figure{
  background-color: white;
  height: 220px;
  width: 220px;
  display: inline-block;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  box-shadow: rgba(31, 42, 75, 0.05) 0px 5px 60px;
  border: 1px solid rgba(28, 77, 223, 0.04);
  margin-bottom: 30px;
  transition-duration: 0.3s;
}

.gold .redemption figure:hover{
  transform: translateY(-2px);
  box-shadow: rgba(31, 42, 75, 0.062) 0px 8px 60px;
  border: 1px solid rgba(57, 61, 71, 0.13);
}

.gold .gold-price{
  padding: 15px 30px 15px 30px;
  box-shadow: 0px 8px 20px rgba(230, 225, 190, 0.7);
  /* border: 1px solid rgba(57, 61, 71, 0.13); */
  margin:auto;
  display: inline-block;
  font-size: 18px;
  border-radius: 30px;
  margin-top: 10%;
  font-weight: bold;
  background-color: #F7B83B;
  color: white;

}

#main h1{
  font-size: 25px !important;
  margin-top: -3%;
}

.payment figure{
  width: 120px;
  margin:auto;
  padding: 30px 10px 40px 10px;
}

.payment .cards{
  background-color: white;
  padding-bottom: 10%;
}

.payment .card{
  border: 1px solid rgb(241, 241, 241);
  border-radius: 20px;
  height: 190px;
  width: 290px;
  margin: auto;
  padding-top: 50px;
  box-shadow: none;
  transition-duration: 0.25s;
  box-shadow:  rgba(31, 42, 75, 0.05) 0px 5px 5px;
}

.payment .card:hover{
  transform: translateY(-10px);
  box-shadow:  rgba(31, 42, 75, 0.05) 0px 5px 60px;
  border: 1px solid rgba(237, 243, 255, 0.849);;
}
.payment img{
  width: 150px;
}

.payment #poster{
  width: 400px;
}

.payment #jd{
  background-color: #F73615;
}

.payment #gap{
  background-color:#021044;
}

.payment .info{
  font-size: 16px !important;
  text-align: center;
  margin-top: 10%;
}

.payment .modal-card-body{
  color: #63768D !important;
  background-color: #fefefe;
  padding: 50px !important;
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid rgb(233, 232, 232) !important;
  border-radius: 10px !important;
}

.payment .modal-card{
  
  font-size: 15px;
}

.payment .modal-card-body p{
  font-size: 16px !important;
}

.payment .modal-card-body h2{
  color: #63768D;
  font-size: 16px !important;
}

.payment .modal-background{
  background-color: rgb(0, 0, 0); 
}

.accountList img{
  width: 45%;
}

.accountOpen #poster{
  width: 70%;
  margin-top: -2%;
}

.exchange .card{
  border: none;
  border-radius: 20px;
  height: 170px;
  width: 290px;
  margin: auto;
  padding-top: 60px;
  box-shadow: none;
  transition-duration: 0.25s;
  box-shadow:  rgba(31, 42, 75, 0.05) 0px 5px 5px;
  
}

svg{
  display: block;
  margin: auto;


}
img{
  display: block;
  margin-left: auto;
  margin-right: auto;

}

.exchange .card:hover{
  transform: translateY(-10px);
  box-shadow:  rgba(31, 42, 75, 0.05) 0px 5px 60px;
  border: none;
}

.widget#whyUs {
  width: 100%;
  height: 100%;
  text-align: left;
  background-color: rgba(24, 55, 97, 0.05);
  border: none;
  border-radius: 15px;
  box-shadow: rgba(47, 60, 102, 0.04) 0px 5px 60px;
}

.widget#whyUs h3{
  font-size: 20px !important;
  color: #495057bb !important;
}
.widget#whyUs div{
  margin-left: 30px;
  
}

.widget#calculator {
  width: fit-content;
  height: min-content;
  background-color: rgba(24, 55, 97, 0.05);
  border: none;
  border-radius: 15px;
  box-shadow: rgba(47, 60, 102, 0.04) 0px 5px 60px;
}

#inputCal input{
  background-color: white;
  border: none;
}

.earningCal .field p{
  font-size: large !important;
}

.earningCal .field input{
  font-size: large !important;
}

.earningCal .field select{
  border: none;
  margin-left: 20px;
}

.earningDisplay p{
  font-size: 20px !important;
}

.earningDisplay span{
  font-size: 30px !important;
  font-weight: bold;
}

.tag {
  height: 2.1em !important;
}

.address p{
  margin-bottom: 1em !important;
}

.loan .card {
  border-radius: 11px;
  text-align: center;
  font-size: large;
  box-shadow: rgba(47, 60, 102, 0.09) 1px 3px 15px;
  width: fit-content;
  height: 101px;

}

.cryptoExchange  input{
  font-size: large !important;
  
}

.cryptoExchange .makeOrder{
  padding-top: 20px;
  margin: auto;
}

.cryptoExchange .makeOrder .addressBox input{
  width: 500px;
}

.receipt {
  background-color: #fff;
  width: auto;
  position: relative;
  padding: 1rem;
  box-shadow: rgba(47, 60, 102, 0.09) 1px 3px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 30px 21px 20px 21px;
}

.receipt p{
  color: #4d6079;
  font-family: monospace !important;
}

.receiptHeader{
  padding-bottom: 20px;
}

.receiptHeaderLogo{
  width: 45px;
  float: left;
  
}

.receiptHeaderTitle p{
  float: right;
  font-size: 15px !important;
  


}
.receiptDate{
  clear:left;
  
}

.receiptBody{
  border-bottom: 2px dashed #a0bae2;
  padding: 1.5rem 0 0;
  margin: 1.5rem 0 0;
  padding-bottom: 1.3rem;
  font-weight: 700;
  font-family: monospace !important;
  color:#021044; 
  font-size: 16px;

}

.orderDetails{
  display: flex;
  justify-content: space-between;
  margin: 0 10px 10px;
    
}

.orderStatus{
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  margin: 0 10px 10px;
  
}

.orderStatus span{
  font-size: 15px !important;
}
.receipt:after {
  background-image: linear-gradient(135deg, #fff 0.5rem, transparent 0), linear-gradient(-135deg, #fff 0.5rem, transparent 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 1rem;
    content: '';
    display: block;
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 100%;
    height: 1rem;
}

.boxSender {
  display: block;
  background-color: #fff;
  width: 40rem;
  
  padding: 1rem;
  box-shadow: rgba(47, 60, 102, 0.09) 1px 3px 15px;
  padding: 30px 31px 30px 31px;
  border-radius: 10px;
  font-family: monospace !important;
  color: #46576e;
  margin-bottom: 30px;
  
}

.senderBody{
  
  font-size: 18px;

}

.receiverBody{
  font-size: 18px;
}

.senderFooter{
  padding-top: 30px;
  font-size: 17px;
}

.senderBody .address{
  word-break:break-all;
  background-color: rgb(235, 241, 252);
  color: #1f51a1;
  padding: 8px 15px 8px 15px;
  border-radius: 8px;
  font-weight: 700;
}

.receiverBody .address{
  word-break:break-all;
  font-weight: 500;
  color: #4d6079;
}
.countdown span{
  font-size: 20px !important;
}

.countdown {
  color:#021044
}

.navbadge {
  display: inline-block;
  background-color: lighten(red, 20%);
  /* border-radius: 50%; */
  color: rgb(212, 42, 42);
  padding: 0.5em 0.75em;
  position: relative;
}

.pulsate {
  content: '';
  display: block;
  position: absolute;
  right: -38px;
  top: 5px;
  animation: pulse 1.5s ease infinite;
  /* border-radius: 50%; */
  /* border: 14px double lighten(red, 20%);  */
 
  
}

#sideButton{
  font-size: 13px;
  width: fit-content;
  box-shadow: none;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.4);
    opacity: 0.7;
  }
}
