h1, h2, p{
    font-family: Sen, 'Roboto',"Microsoft JhengHei", "Hiragino Sans GB","Microsoft YaHei" !important; 
}

h1 {
    color: #45505F !important;
    font-size: 36px !important;
}

h3 {
    color: #828282 !important;
    font-size: 18px !important;
}
.landing{
    background-image: url("../image/bg.png");
    background-size: cover;
    background-color: #F9FAFC;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
     -ms-animation: fadein 2s; /* Internet Explorer */
      -o-animation: fadein 2s; /* Opera < 12.1 */
         animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.landing-caption{
    padding-top: 5%;

}

.landing-caption .button{
    margin-top: 25%;

}

.widget{
    /* background:linear-gradient(249.91deg, #4d92ec -3.48%, rgba(103, 161, 236, 0.61) 107.71%); */
    
    /* background: #FAFAFA; */
    border-radius: 3px;
    text-align: center;
    margin: auto !important;
    padding: 40px 40px 10px 40px;
    height: 360px; 
    width: 300px;
    margin-top: 40px !important;
    box-shadow: rgba(31, 42, 75, 0.05) 0px 5px 60px;
    border: 1px solid rgba(31, 42, 75, 0.04);
    
    
}

.heading{
    font-size: 19px !important;
}

.plain-text {
    color: grey;
    font-weight: bold;
}

.widget>p{
    font-size: 18px;
    font-weight: bold;
}



.feature{
    
    margin-top: 20%px;
    margin-bottom: 150px !important;
   
}

.faq-background{
    padding: 40px 40px 40px 40px;
    box-shadow: rgba(31, 42, 75, 0.05) 0px 5px 60px;
    
 
}
.partners img{
    display: block;
    text-align: center !important;
    filter: sepia(100%);
    mix-blend-mode: luminosity;
    opacity: 0.6;
    padding: 2%;
    margin-left: auto;
    margin-right: auto;
}

.saving .partners img{
    display: block;
    text-align: center !important;
    filter: grayscale(100%);
    mix-blend-mode: luminosity;
    opacity: 0.8;
    padding: 2%;
    margin-left: auto;
    margin-right: auto;
}

.saving .partners img#dlrlogo{
    width: 80%;
    margin-top: 10%;
}

.saving .partners img#transferwise{
    width: 90%;
    margin-top: -5%;
}

.partners img#circle{
    width: 60%;
}

.partners img#nydfs{
    width: 30%;
    padding-top: 5%;
}

.saving .partners img#nydfs{
    width: 45%;

}

.partners img#aliance{
    width: 40%;
    padding-top: 15%;
}

.partners img#currencycloud{
    width: 40%;
}

.partners img#cfsb{
    width: 50%;
}

.partners img#transactive{
    width: 70%;
    padding-top: 18%;
}

.partners img#primetrust{
    width: 50%;
    padding-top: 5%;
}
.partners img#bcb{
    width: 50%;
    padding-top: 15%;
}


.test{
    background-color: red;
}
.partners svg{
    filter: grayscale(100%);
    mix-blend-mode: luminosity;
    opacity: 0.5;
    margin-left: 25%;
    margin-right: auto;
}

ul>li{
    font-weight: bold;
}

#kunni {
    margin-top: 15%;
}


