@media all and (min-width: 80px) {
    #list {
      max-width: 680px;
      /* margin-top:10px; */
      /* box-shadow: 0px 0px 10px 1px  rgba(170, 185, 202, 0.4); */
      transition: all 0.3s;
      text-overflow: ellipsis;
      /* border-radius: 0px; */
      padding-top: 10px;
      /* background-color: #F6F7F8 ; */
      
    }  

    #panel {
        max-width: 380px;
        padding: 60px 25px 30px 25px;
        
    }



    #panel a.active{
        
        font-weight: bold;
        border-bottom: 3px solid #3273DC;
        
        
    }

    #list a.active{
        font-weight: bold;
        border-bottom: 3px solid #3273DC;
    }

    
    .card-content a {
        font-size: 16px;
    }


    ion-icon {
        color:red;
    }
   
    .note {
        /* display: block; */
        font-size: 16px;
        letter-spacing: 1px;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-weight: bold !important;
        font-family: Sen !important;
    }
    /* .hiddenRow {
        ;
    }

    .fillform-card {
    
    } */

    .field{
        padding-bottom: 10px ;
    }

    #loading{
        text-align: center;
        /* background: rgba(0, 0, 0, 0.05); */
        border-radius: 4px;
        margin-bottom: 20px;
        padding: 180px 50px;
        margin: 20px 0;
    }
  

}

@media all and (min-width: 480px) {
    .summary{
        max-width: 1050px; 

    };




    .Mono{
        font-family: 'PT Mono', monospace !important;

    }
    svg{
        /* margin-top: 5px; */
        
        
    }



}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

#main {
    background-color: #F9FAFC;
    padding-bottom: 300px
}

.round-card{
    /* padding: 60px 30px;
    margin: auto;
    max-width: 350px; */
    box-shadow: rgba(31, 42, 75, 0.10) 0px 5px 60px;
    border: 1px solid rgba(66, 98, 194, 0.1);
    border-radius: 10px !important;
    /* box-shadow: rgba(31, 42, 75, 0.05) 0px 5px 60px; */
    background-color: white;
}