
.select select{
	border-color: F5F7FB;
	border-radius: 25px;
	background-color: F5F7FB;
}

.select select:hover{
	border-color: rgba(240, 240, 245, 1);

}

.ant-modal-mask{
	background-color: rgba(0, 0, 0, 0.05) !important;
	

}

.ant-modal-wrap{
	
	backdrop-filter: blur(2px);

}





.modal-background{
	
	background-color: grey;

	opacity: 10%;
	
}
input{
	font-size: 15px !important;
}

.modal{
	backdrop-filter: blur(2px);
	

}

#bankForm{
	padding: 60px 25px 30px 25px;
	
}

.country{
	font-size: 15px;
}

.card#bankForm{
	border-radius: 3px ;
}










