nav {
  padding-top: 0px;
  /* padding-bottom: 20px; */
  /* background-color: white !important; */
  
}

.navbar {
  font-weight: bold;
  
   

}

.navbar-brand {
  font-size: 25px;
  font-weight: bold;
}

.navbar-end{
  transition: all .3s !important;
  color: #819DBA;
  
}

.navbar-end li{
  font-size: 16px;  
}

.navbar-end li:hover{
  border-bottom: 2px solid transparent !important;
  
}

/* li a:focus{
  border-bottom: 2px solid transparent !important;
} */

.navbar-end li a{
  color:  #7092b6 !important;
  font-weight: normal;
  /* padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px; */
  /* border-bottom: 1px solid transparent !important; */
  transition-duration: 0.5s !important;
}

.navbar-end li a:hover{
  color: #1C3574 !important;
  
  /* border-bottom: 1.5px solid #1C3574 !important; */
  
}







.navbar .container{
  /* min-height: 4rem; */
  margin-left: 8%;
  margin-right: 8%;
}


.navbar .navbar-end {

  align-items: center;

}

.button{
  border: 1px solid transparent !important;
  box-shadow: rgba(31, 42, 75, 0.11) 0px 5px 10px;
  transition: all .3s !important;
}
  
.navbar-end.button {
  display: inline-block;
  background-color:transparent;
  
}

.navbar-end .button:hover {
  box-shadow: 0px 8px 20px rgba(212, 226, 241, 0.8);
  border: 1px transparent solid;

  transform: translateY(-2px);
  
}



.has-nav-space {
  margin-right: 8%;
}

.signup {
  background-color: #0057DE;
  color: white !important;  
  margin-right: 8px;
}

.signup:hover {
  background-color: rgba(28, 53, 116) !important;
}

#login {
  color: #47719A !important;
}





