

.foot-nav {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 90px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    
    background-color: white;
    -webkit-box-shadow:  rgba(31, 42, 75, 0.11) 0px 5px 60px;
    -moz-box-shadow:  rgba(31, 42, 75, 0.11) 0px 5px 60px;
    box-shadow: rgba(31, 42, 75, 0.11) 0px 5px 60px;
    display: flex ;
    justify-content: space-between;
    overflow-x: auto !important;
    z-index: 1;
    border: 1px solid rgba(0, 0, 0, 0.068);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* font-weight: bold; */
    
}

.nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    flex-grow:1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    color: #45505F !important;
    



    
}