
.login-container {
    /* background:url(../image/pablo-order-completed.png); */
    padding-bottom: 15%;
    background-repeat: no-repeat;
    background-size: cover;
}
@media all and (min-width: 480px) {
    .Login {
      padding: 60px 30px;
      margin: auto;
      max-width: 350px;
      border: 1px solid rgba(0, 0, 0, 0.068);
      border-radius: 17px !important;
      box-shadow: rgba(31, 42, 75, 0.11) 0px 5px 60px;
      background-color: white;
    }

    .input {
        background-color: rgba(243, 244, 245, 0.726) ;
        border: 2px solid transparent;
        border-radius: 30px;
        font-family: Sen;
        transition: all 0.4s;
        font-weight: bold;
    }
    .input:hover{
        border: 2px solid transparent !important;     
    }

    .is-bold {
        font-weight: bold;
    }

    .input, .textarea {
        /* box-shadow: inset 0 0.0625em 0.125em rgba(10,10,10,.05); */
        box-shadow: none !important;
    }

    .input, .select select, .textarea {


        color: #363636cc;
    }
    .label {
        color: #363636cc !important;
    }

    p{
        font-size: 13px;
    }


}