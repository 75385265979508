body {
  margin:  0;
  font-family: Sen, 'Roboto',"Microsoft JhengHei",  "Hiragino Sans GB","Microsoft YaHei" !important;
  /* font-family: "Open Sans", BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

  
  


}

@media all and (max-width: 768px){
  body{
    padding-bottom: 70px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Sen, 'Roboto',"Microsoft JhengHei", "Hiragino Sans GB","Microsoft YaHei";
}

p{
  font-size: 15px !important;
  margin-bottom: 0 !important;
}
.control .icon {
  pointer-events: initial !important;

}

